.header-bg {
    background-image: url(../Assets/HeaderBkg.jpg);
    object-fit: cover;
    margin-top: 0px;
    height: 300px;
}

.navigation {
    top: 0;
    display: flex;
    background-color: rgba(30, 30, 30, 0.9);
    border-bottom: solid 1px #000;
    height: 60px;
    width: 100%;
    color: whitesmoke;
    text-decoration: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.nav-title {
    position: absolute;
    left: 0;
    right: 00;
    top: 75px;
    align-items: center;
    text-align: center;
}

.logo-link a:link,
a:visited,
a:hover,
a:active {
    color: #f5f5f5;
    text-decoration: none;
}

.logo {
    margin-top: 10px 0px;
    height: 200px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 0px 2px 2px rgba(30, 30, 30, 0.9);
}

.nav-list {
    margin-right: 10%;
    display: flex;
    margin-left: auto;
    align-items: center;
}

.nav-list ul {
    display: flex;
    flex-wrap: wrap;
    z-index: 2;
}

.nav-list li {
    display: flex;
    list-style-type: none;
    margin: 0 5px;
    color: #f5f5f5;
    width: 100px;
    text-align: center;
    align-items: center;
}

.nav-list li a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 16px;
    font-weight: 400;
    transition: 0.1s all;
}

.contact-btn {
    position: absolute;
    right: 10%;
    top: 1.5%;
    width: 125px;
    height: 40px;
    background-color: rgba(1, 101, 225);
    border-radius: 5px;
    text-decoration: none;
    border: none;
    color: #f5f5f5;
    font-weight: 400;
    font-size: 15px;
    cursor: pointer;
    transition: 0.2s all;
    z-index: 2;
}

.contact-btn:hover {
    background-color: #17A9FD;
    box-shadow: 0 0px 2px 2px rgba(30, 30, 30, 0.9);
    color: rgba(30, 30, 30, 0.8);
    height: 45px;
    transition: 200ms;
}

.contact-btn:active {
    box-shadow:
        7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    transform: translateY(2px);
}

a {
    font-size: 16px;
    font-weight: 400;
    color: #f5f5f5;
    cursor: pointer;
    text-decoration: none;
    transition: 0.1s all;
}

.nav-list li a:hover {
    opacity: 0.6;
    border-bottom: 2px solid rgb(245, 245, 245, 0.7);
}

.nav-list li a.active {
    border-bottom: 2px solid whitesmoke;
    
}

.contact-link {
    display: none;
}

.hamburger {
    border: 0;
    height: 36px;
    width: 36px;
    padding: .125rem;
    position: absolute;
    top: 10px;
    right: 5%;
    color: white;
    cursor: pointer;
    display: none;
}

.hamburger:hover {
    cursor: pointer;
    color: rgba(1, 101, 225);
    transition: 0.4s;
}



.close-menu {
    position: absolute;
    top: 15px;
    right: 6%;
    border: 0;
    height: 32px;
    width: 32px;
    color: whitesmoke;
    cursor: pointer;
    display: none;
}

.close-menu:hover {
    cursor: pointer;
    color: rgba(1, 101, 225);
    transform: rotate(-90deg);
    transition: 0.5s;
}

@media screen and (max-width: 1550px) {
    .header-bg {
        background-position: -350px;
    }
}

@media screen and (max-width: 999px) {
    .header-bg {
        height: 300px;
        background-position: -550px -10px;
    }

    .hamburger {
        display: block;
    }

    .nav-list ul {
        position: absolute;
        top: 45px;
        right: 0px;
        display: none;
        flex-direction: column;
        width: 45%;
        height: calc(100% - 60px);
        background-color: rgb(0, 0, 0, 0.93);
        border-radius: 3px;
        text-transform: uppercase;
        transition: 0.4s ease-in;
    }

    .nav-list li {
        margin: 0px;
        height: 65px;
        width: auto;
        border-bottom: 1px solid rgba(245, 245, 245, 0.7);
        padding-left: 20px;
    }

    .nav-list li a {
        text-align: left;
        width: 100%;
        padding-top:20px;
        height: 35px;
        margin: 0px;
    }

    .contact-link {
        display: block;
    }

    .contact-btn {
        margin: auto;
        display: none;
    }

    .nav-list li a:hover {
        color: rgba(1, 101, 225);
        border-bottom: 0px solid;
    }

    .nav-list li a.active {
        border-bottom: 0px;
        color: rgba(1, 101, 225);
        font-weight: 700;
    }

    .nav-list.expanded ul {
        display: block;
    }

    .nav-list.expanded .close-menu {
        display: block;
    }

    .nav-list.expanded .hamburger {
        display: none;
    }

}

@media screen and (max-width: 450px) {
    .nav-list ul {
        width: 100%;
    }
}