.card-zone {
    margin:25px auto;
    width:900px;
    display: flex;
    flex-wrap: wrap;
}
.card-shape {  
    height: 325px;
    width: 275px;
    background-color: #EEEEEE;  
    border-radius: 5px;
    border: .5px solid #CFCFCF;
    margin: 10px auto;
}
.card-logo {
    width: 128px;
    height: 128px;
    margin-top: 10px;
    margin-left: 74px;
}
.card-desc{
    width:fit-content;
    margin: 2px auto;
    font-weight: 500;
}
ul{
    padding-left: 0;
}
@media screen and (max-width: 1100px) {
    .card-zone{
        width: 800px;
    }
}
@media screen and (max-width: 825px) {
    .card-zone{
        width: auto;
    }
}

