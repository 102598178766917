.review-zone{
    width: 1100px;
    margin: 25px auto;
    display: flex;
    flex-wrap: wrap;
    
}
.review-shape{
    width: 350px;
    height: auto;
    margin: 10px auto;
    background-color: #EEEEEE;  
    border-radius: 5px;
    border: .5px solid #CFCFCF;
}
.review-img{
    width: 100%;
    padding:0px;
}
.review-desc { 
    font-size: 13px;
    font-weight: 500;
    width: 90%;
    margin: 5px auto;
    line-height: 20px;
    padding-bottom: 10px;
}
@media screen and (max-width: 1100px) {
    .review-zone{
        width: 800px;
    }
}
@media screen and (max-width: 825px) {
    .review-zone{
        width: auto;
    }
}