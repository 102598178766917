h1 {
  text-align: center;
  margin: 25px auto;
  font-weight: 600;
  font-size: 40px;
  color: rgba(0, 0, 0, .8)
}

h2 {
  color: rgba(0, 0, 0, .8);
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}
h3{
  text-align: center;
}

h4 {
  color: #ebebeb;
  margin: 0px 10px;
  padding: 0px;
  height: fit-content;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -1px;
}

h5 {
  font-size: 20px;
  margin: 0px auto;
  padding-left: 20px;
}

p {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .5px;
}

form {
  max-width:475px;
  margin: 25px auto;
  text-align: left;
  padding: 25px 0px;
}

.form-zone {
  margin: 25px auto;
  margin-top: 75px;
  width: 90%;
  border-top: .5px solid rgba(30,30,30,0.7);
  border-bottom: .5px solid rgba(30,30,30,0.7);
}
input[type=text],[type=email], select {
  display: inline-block;
  font-size: 15px;
  padding-left: 10px;
  margin: 8px;
  height: 40px;
  width: 91.5%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #EEEEEE;  
}
textarea {
  width: 90%;
  height: 250px;
  padding: 10px;
  font-size: 15px;
  margin: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;  
  background-color: #EEEEEE;  
}
input[type=submit] {  
  font-family: 'Inter', sans-serif;
  width: 125px;
  height: 40px;
  background-color: rgba(1, 101, 225);
  color: #ebebeb;
  font-size: 15px;
  font-weight: 400;
  padding: 14px 20px;
  margin: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s all;
}            
.landing-message{
  margin: 25px auto;
  max-width: 1000px;
  width: 90%;
  padding-bottom: 25px;
  border: none;
  border-bottom: .5px solid rgba(30,30,30,0.7);
}
.message-statement{
  margin: 10px auto;
}

.get-in-touch{
  height: 200px;
  margin: 50px auto;
  max-width: 1000px;
  width: 90%;
  border-top: .5px solid rgba(30,30,30,0.7);
  border-bottom: .5px solid rgba(30,30,30,0.7);
}
.get-in-btn {
  display: block;
  margin: 30px auto;
  width: 125px;
  height: 40px;
  background-color: rgba(1, 101, 225);
  border-radius: 5px;
  text-decoration: none;
  border: none;
  color: #f5f5f5;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  transition: 0.2s all;
}
.get-in-btn:hover{
  background-color: #17A9FD;
  filter: drop-shadow(0 0 1rem rgb(30, 30, 30, 0.8));
  color: rgba(30,30,30,0.8);
  height: 45px;
  transition: 200ms;
}
.get-in-btn:active {
  box-shadow: 
    7px 6px 28px 1px rgba(0, 0, 0, 0.24);
  transform: translateY(2px);
}
@media screen and (max-width: 999px) {
  h1 {
    font-size: 25px;
  }

  h2 {
    font-size: 20px;
  }

  h5 {
    text-align: center;
    padding-left: 0px;
  }
  form {
    width: 100%;
  }
  input[type=text],[type=email], select {
    width: 92%;
  }
  textarea {
    width: 90%;
  }
  .form-zone {
   padding-top: 50px;
  }
}