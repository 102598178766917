.contact-method {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}
.contact-shape{
    height: 275px;
    width: 275px;
    margin: 20px auto;
    background-color: #EEEEEE;  
    border-radius: 5px;
    border: .5px solid #CFCFCF;
}
.contact-logo{
    height: 100px; 
    margin-top: 10px;
}
.contact-desc {
    font-size: 18px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    line-height: 1.75;
    font-weight: 400;
}