.footer {
    background-image: url(../Assets/FooterBG.jpg);   
    background-position: top;
    object-fit: cover;
    height: 250px;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    color: #f5f5f5;
    display: flex;
    flex-wrap: wrap;
}

.social-media{
    margin-left: 10%;
    margin-right: auto;
    text-align: left;
    align-items: center;
    padding: 0;
    height: 200px;
    display: flex;
    flex-wrap: wrap;
}
.social-group{
    text-align: center;
    margin: auto;
}
.social-icons {
    font-size: 28px;
    margin: 5px;
}
.rights-reserved {  
    display: inline-block;
    width: 100%;
    padding-bottom: 0px;
    margin-bottom: 0px;
    bottom: 0px;
    align-items: center;
    text-align: center;
    font-size: 15px;
}
p{
    padding: 0px;
    margin: -2px;
}
.contact-us{
    height: 200px;
    margin-right: 10%;
    margin-left: auto;
    margin-top: 50px;
    padding: 0px;
}
.contact-us h3{
    font-size:30px;
    font-weight: 400;
    margin: 10px 40px;
}
li {
    list-style-type: none;
    padding: 0;
    margin: 10px 0px;
    font-size: 16px;
    font-weight: 400;
}
.phone-number {
    text-decoration: none;
    color:#f5f5f5;
}

@media screen and (max-width: 825px) {
    .footer {
        height: auto;
    }
    .social-media {
        height: 100px;
        margin: auto;
    }
    .social-media h1{
        font-size: 25px;
        text-align: center;
    }
    .contact-us{             
        width: 100%;   
        height: auto;        
        margin-top: 0px;
        margin-bottom: 20px;
    }
    .social-icons {
        font-size: 28px;

    }
    .contact-us h5{
        text-align: left;
        font-size:20px;
        margin-left:0px
    }
    ul{
        list-style: none;
    }
}