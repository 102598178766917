.contact-details {
    width: 1000px;
    margin: 0px auto;
}
.shop-photo {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 5px;
    margin: 25px auto;
}
.shop-map {
    border-radius: 5px;
    margin-bottom: 50px;
}
@media screen and (max-width: 1000px) {
    .contact-details{
        width: auto;
    }
    .shop-photo{
        height: 350px;
    }
    .shop-map {
        width: 100%;
        height: 350px;
    }
}

